<template>
  <div class="l-c">
    <el-form ref="ruleForm2" :model="ruleForm2" :rules="rules2" label-position="right" label-width="0"
      class="demo-ruleForm login-container" status-icon>
      <div class="login-form">
        <img class="logo" :src="require('../../assets/image/logo.png')" />
        <el-form-item prop="login_type" style="width: 100%">
          <el-select v-model="ruleForm2.login_type" style="width: 100%" placeholder="Please select login type" @change="loginTypeSel">
            <el-option value="Student" label="Student"></el-option>
            <el-option value="Advisor" label="Advisor"></el-option>
            <el-option value="SchoolAdmin" label="School Admin"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="loginType==='Student'" prop="student_email" style="width: 100%">
          <el-input v-model="ruleForm2.student_email" type="text" auto-complete="off"
                    placeholder="Please login with your email" />
        </el-form-item>
        <el-form-item v-if="loginType!=='Student'" prop="employee_email" style="width: 100%">
          <el-input v-model="ruleForm2.admin_email" type="text" auto-complete="off"
            placeholder="Please login with your email" />
        </el-form-item>
        <el-form-item v-if="loginType!=='Student'" prop="employee_pwd" style="width: 100%">
          <el-input v-model="ruleForm2.admin_pwd" auto-complete="off" placeholder="Please enter your password"
            type="password" />
        </el-form-item>
        <el-form-item v-if="loginType==='Student'" prop="verify_code" style="width: 100%">
          <el-input v-model="ruleForm2.verify_code" auto-complete="off" placeholder="Please enter the code"
            type="text" >
            <el-button slot="suffix" class="send_code" @click="sendCode">Send Code</el-button>
          </el-input>
        </el-form-item>
        
        <span v-if="!!error_text" style="color: red" class="error_text">{{
          error_text
          }}</span>
        <el-form-item style="width: 100%">
          <el-button :loading="logining" type="primary" style="width: 100%" @click="submit">Login</el-button>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import { loginByStudent, loginByAdvisor, loginByAccountAdmin, createLoginOPT, loginByStudentV2 } from "../../api/eae";
import {
  setUserId,
  setUserName,
  setUserAccountCountry,
  setUserType,
  setAccountId,
} from "../../utils/store";
//2个账号：yinzehu@sina.cn， 密码  ： impact 可以当老师和学生，还有一个是你的账号，当学生：chen.zhibo@hotmail.com 密码 = myideamatters
export default {
  name: "Login",
  data() {
    return {
      error_text: "",
      logining: false,
      ruleForm2: {
        admin_email: "",
        admin_pwd: "",
        login_type: "Student",
        student_email: "",
        verify_code: "",
      },
      loginType: 'Student',
      rules2: {
        reviewer_email: [
          {
            required: true,
            message: "Please enter your email",
            trigger: "blur",
          },
        ],
        reviewer_pwd: [
          {
            required: true,
            message: "Please enter your password",
            trigger: "blur",
          },
        ],
        login_type: [
          {
            required: true,
            message: "Please select login type",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    loginTypeSel(val){
      console.log(val,'loginType')
      this.loginType = val
    },
    sendCode(){
      if (this.ruleForm2.student_email == "") {
        this.$message.error("Please enter your email");
        return;
      }
      createLoginOPT(this.ruleForm2.student_email).then((res) => {
        console.log(res.data,'createLoginOPT')
        let result = res.data
        if(result.code === 0){
          this.$message.success('Sent Successfully');
        }else{
          this.$message.error('Verification code sent failed. Please try again.')
        }
      })
    },
    submit() {
      console.log('切换服务器222')
      this.logining = false;
      if(this.loginType === "Student"){
        if (this.ruleForm2.student_email == "") {
          this.$message.error("Please enter your email");
          return;
        }
        if (this.ruleForm2.verify_code == "") {
          this.$message.error("Please enter the code");
          return;
        }
      }else{
        if (this.ruleForm2.admin_email == "") {
          this.$message.error("Please enter your email");
          return;
        }
        if (this.ruleForm2.admin_pwd == "") {
          this.$message.error("Please enter your password");
          return;
        }
      }
      /*if (this.ruleForm2.login_type == "") {
        this.$message.error("Please select login type");
        return;
      }*/
      if (
        this.ruleForm2.admin_email === "admin" &&
        this.ruleForm2.admin_pwd === "yiquanTodo"
      ) {
        setUserId("admin");
        setUserName("admin");
        setUserType("admin");
        setUserAccountCountry("China");
        this.$UserData.user_name = "admin";
        this.$UserData.account_country = "China";
        this.$router.push("/home/adminform");
        return;
      }

      if (this.ruleForm2.login_type == 'SchoolAdmin') {
        loginByAccountAdmin(this.ruleForm2.admin_email, this.ruleForm2.admin_pwd).then((res) => {
          let result = res.data
          if (result.code == 0) {
            setUserId(result.data._id.$id);
            let account_country = "China";
            if (result.data.account_country) {
              account_country = result.data.account_country;
            }
            setUserAccountCountry(account_country);
            let user_name = result.data.account_nameEN;
            if (!user_name || user_name == '') {
              user_name = result.data.account_name;
            }
            setUserName(user_name);
            setUserType("schoolAdmin");
            setAccountId(result.data._id.$id);
            this.$UserData.user_name = user_name;
            this.$UserData.account_country = account_country;
            this.$router.push("/home/news&updates");
          } else {
            this.error_text = result.msg;
          }
        })
      }else if (this.ruleForm2.login_type === "Advisor") {
        loginByAdvisor({
          contact_email: this.ruleForm2.admin_email,
          contact_pwd: this.ruleForm2.admin_pwd,
        }).then((res1) => {
          let result1 = res1.data;
          if (result1.code == 0) {
            setUserId(result1.data._id.$id);
            let account_country = "China";
            if (result1.data.account_country) {
              account_country = result1.data.account_country;
            }
            setUserAccountCountry(account_country);
            const user_name =
              result1.data.contact_givenName + ' ' + result1.data.contact_lastName;
            setUserName(user_name);
            setUserType("advisor");
            setAccountId(result1.data.account_id);
            this.$UserData.user_name = user_name;
            this.$UserData.account_country = account_country;
            this.$router.push("/home/accountapps");
          } else {
            this.error_text = result1.msg;
          }
        });
      } else {
        //loginByStudentV2(student_email,verify_code)
        loginByStudentV2(
          this.ruleForm2.student_email,
          this.ruleForm2.verify_code
        ).then((res) => {
          let result = res.data;
          if (result.code == 0) {
            setUserId(result.data._id.$id);
            let account_country = "China";
            if (result.data.account_country) {
              account_country = result.data.account_country;
            }
            setUserAccountCountry(account_country);
            const user_name =
              result.data.student_givenName_pinyin + ' ' + result.data.student_lastName_pinyin;
            setUserName(user_name);
            setUserType("student");
            setAccountId(result.data.account_id);
            this.$UserData.user_name = user_name;
            this.$UserData.account_country = account_country;
            this.$router.push("/home/news&updates");
          /*} else if (result.code == -1) {
            loginByAdvisor({
              contact_email: this.ruleForm2.admin_email,
              contact_pwd: this.ruleForm2.admin_pwd,
            }).then((res1) => {
              let result1 = res1.data;
              if (result1.code == 0) {
                setUserId(result1.data._id.$id);
                let account_country = "China";
                if (result1.data.account_country) {
                  account_country = result1.data.account_country;
                }
                setUserAccountCountry(account_country);
                const user_name =
                  result1.data.contact_givenName + ' ' + result1.data.contact_lastName +
                  setUserName(user_name);
                setUserType("advisor");
                setAccountId(result1.data.account_id);
                this.$UserData.user_name = user_name;
                this.$UserData.account_country = account_country;
                this.$router.push("/home/accountapps");
              } else {
                this.error_text = result1.msg;
              }
            });*/
          } else {
            this.error_text = 'Incorrect verification code';
          }
        });
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.l-c {
  width: 100%;
  height: 100vh;
  position: relative;
  background: url("~@/assets/image/loginBg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: 0 0;

  .login-container {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    background-clip: padding-box;
    width: 40%;
    padding: 7%;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 60%;

    .login-form {
      display: flex;
      flex-direction: column;
      align-items: center;

      /deep/.el-input__inner {
        border-radius: 20px;
        background: #ffefed;
        border: 2px solid #ffe6e3;

        &::placeholder {
          color: #666666;
        }
      }
    }

    .logo {
      width: 50%;
      margin-bottom: 40px;
    }

    .title {
      margin: 0px auto 10px auto;
      text-align: center;
      color: #505458;
    }

    .remember {
      margin: 0px 0px 35px 0px;
    }

    .el-radio {
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
}

.error_text {
  color: red;
  // margin-bottom: 10px;
}

/deep/ .el-input__suffix{
  right: 0;
}
.send_code{
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}
</style>
